import React from 'react'
import * as WhyStyles from './WhyGetInvolved.module.scss'

const WhyGetInvolved = () => {
  return (
    <section className={WhyStyles.WhyIntro}>
      <div className={WhyStyles.Wrapper}>
        <h3>Why Get Involved</h3>
        <div className={WhyStyles.Cards}>
          <div className={WhyStyles.Card}>
            <h4>Gain Trust</h4>
            <p>
              3.8m ABC1 women each month read Muddy Stilettos and explicity
              trust our brand and its recommendations.
            </p>
          </div>
          <div className={WhyStyles.Card}>
            <h4>Show Your Support</h4>
            <p>
              Enter our Awards and show your staff how much you appreciate their
              efforts.
            </p>
          </div>
          <div className={WhyStyles.Card}>
            <h4>Effective PR</h4>
            <p>
              Winning at a brand new, modern, forward-thinking Awards is great
              publicity for your school.
            </p>
          </div>
          <div className={WhyStyles.Card}>
            <h4>Be a Winner</h4>
            <p>
              We&apos;ll champion all winners and showcase the schools on our
              website and social channels.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyGetInvolved
