import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import BSAShortHeader from '../../components/BestSchoolsAwards/Header/ShortHeader'
import React from 'react'
import { Helmet } from 'react-helmet'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const BSACategories = () => {
  return (
    <>
      <Helmet>
        <title>
          The Categories | Muddy Stilettos&apos; Best Schools Awards | Muddy
          Stilettos
        </title>
      </Helmet>
      <BSAShortHeader />
      <Categories />
      <WhyGetInvolved />
      <BSAContent>
        <div style={{ textAlign: 'center' }}>
          <h1>Our partners</h1>
          <p>
            To find out how to get involved as a partner, contact Jamie Page
            Weeden at{' '}
            <a href="mailto:jamie@muddystilettos.co.uk">
              jamie@muddystilettos.co.uk
            </a>
            .
          </p>
          <a
            className={ContentStyles.RegisterInterestButton}
            href={`mailto:bestschools@muddystilettos.co.uk`}
          >
            Register Interest
          </a>
        </div>
      </BSAContent>
      <BSAFooter />
    </>
  )
}

export default BSACategories
